<template>
  <b-modal
    size="md"
    id="transacction-agreement-modal"
    ok-only
    centered
    hide-footer
		hide-header
    no-close-on-backdrop
    no-close-on-esc
    modal-bg-variant="custom-opacity"
    header-class="bg-white p-0 pr-1"
    @close="dataPushAction('closeTransacctionAgreementModal', 'closeTransacctionAgreementModal')"
    @shown="dataPushAction('openTransacctionAgreementModal', 'openTransacctionAgreementModal')"
  >

    <b-overlay :show="isTakingAChoice">
      <ValidationObserver
        ref="transacctionAgreementModal"
        tag="form"
        v-slot="{ invalid }"
      >
        <b-form>
          <div class="modal-container">
            <div class="content-accept" >
              <!-- <p class="p-intro">Test Text</p> -->

              <p class="p-main">{{ $t('Do you agree with the benefit transactions?')}}</p>

              <div class="text-center">
                <b-button
                  class="mb-1 btn-accepts"
                  variant="primary"
                  :href="`${authURL}FivesClub/benefitbalance/${myContracts.contractSelected.id}/${selectedYear}/`"
                  target="_blank"
                  rel="noopener noreferrer"
                  @click="dataPushAction('downloadFile', 'benefitBalance')"
                ><feather-icon icon="FileTextIcon" /> {{$t('balance')}}
                </b-button>
              </div>

              <!-- <p>Test Text</p> -->
            </div>

            <div class="mb-1 mt-1 border rounded p-1" v-if="adminResponse">
              <label>{{ $t('Admin response') }}:</label> <br>
              <span class="font-weight-bold">{{ adminResponse.adminComments }}</span> <br>
              <label class="font-weight-bold">- {{adminResponse.reviewedBy}} {{$t('at')}} {{ formDate(adminResponse.reviewDate) }}</label><br>

            </div>

            <div class="mb-2">
              <ValidationProvider :name="$t('Comments')" rules="max:500|min:10">
                <b-form-group slot-scope="{ errors }">
                  <label> {{ $t('Comments')}} </label>
                  <b-form-textarea
                    v-model="comments"
                    id="comments-text-area"
                    rows="3"
                    max-rows="6"
                    :state="errors.length > 0 ? false : null"
                  />
                  <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </ValidationProvider>
            </div>

            <!-- <pre>{{ adminResponse }}</pre> -->

            <div class="container-buttons">
              <button class="btn-accepts" :disabled="invalid || isLoadingOwnerResponse" @click.prevent="setOwnerChoice('1')"> {{ $t('accept') }}</button>
              <button class="btn-accepts" :disabled="invalid || isLoadingOwnerResponse" @click.prevent="setOwnerChoice('2')">{{ $t('reject')  }}</button>
            </div>

          </div>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters  } from 'vuex'
import { utils } from '@/modules/owners/mixins/utils'
import { showAlertMessage, formatDate } from '@/helpers/helpers'

export default {
  mixins: [ utils ],
  props:{
    isTakingAChoice: {
      Type: Boolean,
      required: true,
    },
    selectedYear: {
      Type: [String, Number],
      required: true,
      default: new Date().getFullYear()
    },
    period: {
      Type: Object,
      required: true
    },
    adminResponse: {
      Type: [Boolean, Object],
      required: false
    }
  },
  data() {
    return {
      isLoadingOwnerResponse: false,
      choice: null,
      comments: null,
			authURL: process.env.VUE_APP_IMG_SRC_API,
      dateInit: new Date(new Date().getFullYear(), 0, 1).toDateString(),
      dateEnd: new Date().toDateString(),
      choice:null
    }
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters('auth',['contractSelected']),
    langLocale(){
      return this.$i18n.locale
    },
    transaletDates(){
      return {
        fecha_inicial: formatDate({date: this.period.periodRangeStart, language: this.user?.languageName}),
        fecha_final: formatDate({date: this.period.periodRangeEnd, language: this.user?.languageName})
      }
    }
  },
  methods:{
    ...mapActions('owners', ['getPromosOwner']),
    ...mapActions('owners', ['acceptPromosOwner']),
    async closeModal(){
      this.$root.$emit('bv::hide::modal', 'transacction-agreement-modal')
      // await this.pushData('onClick', 'declineToSharePromotions', 'declineToSharePromotions', this.$route.name) // push a back del registro
    },
    async setOwnerChoice(choice){
      this.isLoadingOwnerResponse = true
      this.choice = choice
      await this.dataPushAction('selectOption', choice === '1' ? 'Acepted' : 'Rejected')
      // validar si es admin desde mixin en utils
      if( this.isAdmin ){
        this.$root.$emit('bv::hide::modal', 'transacction-agreement-modal')
        return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
      }
      const valid = await this.$refs.transacctionAgreementModal.validate()
      if (choice == '2' && !this.comments) {
        return showAlertMessage( this.$t('please'), 'InfoIcon', this.$t('comments required'), 'warning', 4500, 'bottom-right')
      }

      if (valid) this.$emit('send-choice', {
        idOwner: this.user.idOwner,
        idContract: this.contractSelected.id,
        choice,
        comments: this.comments,
        period: this.period,
        adminResponse: this.adminResponse
      })
      this.isLoadingOwnerResponse = false
    },
    async dataPushAction(action, aditionalInfo){
			await this.pushData('onClick', action, aditionalInfo, this.$route.name ) // push a back del registro
    },
		formDate(date){
			return formatDate({date, language: this.user?.languageName})
		}
  },
}
</script>

<style>
/* Estilo personalizado para quitar el border-radius del b-modal */

#transacction-agreement-modal .modal-content {
  border: 6px solid #6b6a6a;
  border-radius: 0!important;
  /* box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1); */
}

#transacction-agreement-modal .modal-header button{
  border: 0.27rem solid #6b6a6a;
  border-radius: 0!important;
}

.container-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-accepts {
  background-color: #333; /* Gris oscuro */
  color: #fff; /* Texto blanco */
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  margin: 5px;
  cursor: pointer;
}

/* Estilos adicionales para pantallas más grandes */
@media (min-width: 768px) {
  .container-buttons {
    flex-direction: row;
    justify-content: space-around;
  }

  .btn-accepts {
    margin: 5px;
  }
}

.content-accept {
  text-align: justify;
  color: black;
}

.p-intro span {
  font-style: italic;
  font-weight: bold;
}

.p-main a{
  font-weight: bold;
}

#comments-text-area::-webkit-scrollbar {
  width: 10px;
}
/*
{ opacity: .3; background-color: rgb(0,0,0,.3); height: 100%; } modal <--cuadro gris (border) element { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); display: block; background: #999; width: 600px; height: 300px; padding: 5px; } inner modal <--cuadro blanco element { background: #fff; display: block; width: 100%; height: 100%; padding: 10px; text-align: justify; } clase para los nombres de marcas .hlgt { font-style: italic; font-weight: bold; } */

</style>
